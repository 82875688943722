import * as React from 'react'
import { graphql } from 'gatsby'
import InternalLayout from '../components/InternalLayout'
import Seo from '../components/Seo'
import {
  Flex,
  Box,
  Heading
} from '@chakra-ui/react'
import { RichText } from 'prismic-reactjs'
import SliceZone from '../components/SliceZone/SliceZone'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import Sidebar from '../components/Sidebar/Sidebar'
import MooseLottie from '../components/Footer/Moose/MooseLottie'
import { CustomLink } from '../utils/customLink'

const PublicWorksPageTemplate = (props) => {
      // Explicitly delcare props and destructure the other properties:
      const { location, data } = props;
      const pageLocation = location.pathname

  if (!data) return null; 

  const document = data.prismicPublicWorksPage.data;
  const menuItems = null || document.sidebar_menu.document?.data.body;

    return (
    <InternalLayout>
      <Seo title={document.page_title.text}  />
      
      {/* <PageNoHero
       bgColor="#667743"
      >
        <TitleBar pageTitle={document.page_headline?.text} />
      </PageNoHero> */}

      <Box position="relative" zIndex="1">
        <Flex w="100%" h="100%" flexWrap="wrap" flexDirection={{base: 'column-reverse', lg: 'row'}}>
            <Box w={{base: '100%', lg: '25%'}}>
              <Sidebar
                menuHeading={menuItems && document.sidebar_menu.document.data.menu_title.text}  
                menuItems={menuItems}
                pageLocation={pageLocation}
              />
            </Box>
          <Box w={{base: '100%', lg: '75%'}} bg="white" p="8" mb={{base: '4rem', md: '36rem'}}>
            <Heading as="h2" variant="page-title">
              {document.page_title.text}
            </Heading>
            <Heading as="h3" fontSize="2rem" variant="page-subtitle">
              {document.subtitle.text}
            </Heading>

        <RichText render={document.page_content.richText} serializeHyperlink={CustomLink}/>

        <SliceZone sliceZone={document.body}/>

        </Box>
        <MooseLottie />
        </Flex>
      </Box> 
    </InternalLayout>
  )
}



    export const query = graphql`
    query PublicWorksPageQuery($uid: String!) {
      prismicPublicWorksPage(uid: {eq: $uid}) {
        _previewable
    uid
    type
    data {
      page_headline {
        text
      }
      page_title {
        text
      }
      page_content {
        richText
      }
      subtitle {
        text
      }
      sidebar_menu {
            document {
              ... on PrismicSidebarNavigation {
                id
                data {
                  menu_title {
                    text
                  }
                  body {
                    ... on PrismicSidebarNavigationDataBodySidebarNavAccordionItem {
                      id
                      slice_type
                      primary {
                        title_link {
                          url
                        }
                        navigation_group_title {
                          text
                        }
                      }
                      items {
                        link {
                          url
                        }
                        item_title {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
      body {
        ... on PrismicPublicWorksPageDataBodyContactCard {
          id
          slice_type
          primary {
            department_contact_information {
              text
            }
          }
          items {
            contact_title {
              text
            }
            contact_phone {
              text
            }
            contact_name {
              text
            }
            contact_extension
            contact_email {
              text
            }
          }
        }
        ... on PrismicPublicWorksPageDataBodyBidRfpProjectAccordion {
          id
          slice_type
          primary {
            accordion_title {
              text
            }
            accordion_subtitle {
              text
            }
          }
          items {
            item_title {
              text
            }
            item_description {
              richText
            }
          }
        }
        ... on PrismicPublicWorksPageDataBodyFormLinkModule {
          id
          slice_type
          primary {
            form_links_title {
              text
            }
          }
          items {
            form_links {
              url
            }
          }
        }
        ... on PrismicPublicWorksPageDataBodyRichText {
          id
          slice_type
          primary {
            content {
              richText
            }
          }
        }
        ... on PrismicPublicWorksPageDataBodyRawHtmlSlice {
          id
          slice_type
          primary {
            html_content {
              html
              richText
              text
            }
          }
        }
        ... on PrismicPublicWorksPageDataBodyIframeSlice {
          id
          primary {
            iframe_source {
              text
            }
          }
          slice_type
        }
        ... on PrismicPublicWorksPageDataBodyMeetingTabs {
          id
          slice_type
          primary {
            zoom_meeting_link {
              text
            }
            vimeo_meeting_iframe_embed_code {
              richText
            }
            upcoming_meeting_date(formatString: "MM/D/YYYY")
            upcoming_meeting_agenda_packet {
              richText
            }
            previous_meeting_date(formatString: "MM/D/YYYY")
            link_to_upcoming_meeting_packet_pdf {
              url
            }
            link_to_previous_meeting_packet_pdf {
              url
            }
            link_to_previous_meeting_minutes_pdf {
              url
            }
          }
        }
      }
    }
  }
  }
  `

export default withPrismicPreview(PublicWorksPageTemplate)
